.dropdown-menu{
    position: absolute;
    z-index: 1040;
    display: none;
    min-width: 15rem;
    padding: 0.5rem;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}
.dropdown-menu[property='end']{
    right: 0px;
    left: auto;
}
.dropdown-item {
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 0.25rem 1rem;
    margin: 0.5rem 0px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    // border-bottom: 0.5px solid $color-gray;
    cursor: pointer;

    &:hover{
        background-color: $color-default;
        color: $color-white;
    }
}

.dropdown-menu.show {
    display: block;
}
