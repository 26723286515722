.navbar{
    display: inline-block;
    box-sizing: border-box;
    top: 0px;
    width: 100%;
    padding: 20px 100px;
    position: sticky;
    // border: 1px solid rgb(0, 0, 0);
    // border-bottom: 1px solid rgb(0, 0, 0);
    background-color: white;
    z-index: 1040;
}

.navbar 
.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .nav-toggle{
        display: none;
    }
}

.navbar 
.container
.nav-signup{
    position: relative;
    .profile-img-container{
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        .user_img{
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
        .badge-img{
            position: absolute;
            top: -5px;
            right: -5px;
            font-size: 10px;
        }
    }
    
    .profile-edit{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0px 20px 0px;
        // border-bottom: 0.5px solid $color-gray;

        .profile_img{
            width: 48px;
            height: 48px;
            border-radius: 50%;
        }
    }
}

.navbar 
.container
.navbar-brand
{
    cursor: pointer;
}
.navbar 
.container 
.collapse{
    display: block;
}

.navbar 
.container 
.collapse
.navbar-nav{
    display: flex;
    flex-direction: row;
}

.navbar 
.container 
.collapse
.navbar-nav
.nav-item{
    display: inline-block;
    margin:0px 10px;
    border-bottom: 2px solid $color-white;
    // transition: 0.3s all ease-in-out;
    >.text-link{
        color: rgb(154, 154, 154);
        font-size: 18px;
    }
    >.nav-link{
        color: rgb(154, 154, 154);
        font-size: 18px;
    }
    &:hover{
        border-bottom: solid 2px $color-default;
    }
    &.active{
        >.text-link.active{
        color: $color-blue;
        border-bottom: 2px solid $color-default;
        font-weight: 400;
        }
    }

    .dropdown-toggle {
        white-space: nowrap;
    }
    .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    }

    .dropdown-menu .dropdown-item{
        color: $color-gray;

        &:hover{
            color: $color-white;
        }
    }

    .dropdown-menu[aria-hidden="false"] {
        display: block;
    }

    .dropdown-menu[aria-hidden="true"] {
        display: none;
    }
}

.user-navbar{
    display: flex;
    align-items: center;
    position: fixed;
    left: 250px;
    top: 0;
    right: 0;
    z-index: 1030;
    // padding: 10px 80px;
    height: 80px;
    background-color: #efefef;
    transition: 0.5s all ease;

    .container-fluid{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    
}