.footer{
    padding-left: 100px;
    padding-right: 100px;
    font-size: 14px;
    color: $color-white;
    background-color: #313131;
    padding-top: 50px;

    .footer-section-1{
        padding-bottom: 20px;
        border-bottom: 0.5px solid rgb(255, 255, 255);

        .title{
            color: $color-default
        }
    }

    .footer-section-2{
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .footer-section-2-social{
            display: flex;
            flex-direction: row;
            

            .btn-social{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                border: 1px solid $color-white;
                background-color: transparent;
                cursor: pointer;
                .icon{
                    font-size: 20px;
                    color: $color-white;
                }
            }
        }

        .foot-sec-text{
            color: $color-white;
            margin-right: 20px;
            &:hover{
                color: $color-default;
            }
        }
    }

    .title{
        font-weight: 500;
    }
    ul li{
        list-style: none;
        margin-bottom: 5px;
        a{
            color: $color-white;
            &:hover{
                color: $color-default;
                border-bottom: 0.5px solid $color-default;
            }
        }
    }

    .qr-code-text{
        display: flex;
        justify-content: center;
        font-size: 14px;
        border: 0.5px solid $color-blue;
        margin-bottom: 10px;
        border-radius: 5px;
    }
    .qr-code-img{
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 991px) {
    .footer{
        padding-left: 30px;
        padding-right: 10px;
    
        .dropdown{
            background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>");
            background-repeat: no-repeat;
            background-position: right 10px top;
            background-size: 16px;
        }
    
        ul{
            margin-bottom: 10px;
            li{
                display: none;
            }
            &.active{
                li{
                    display:list-item;
                    // list-style: disc;
                }
            }
        }
        .footer-section-2{
            flex-direction: column;
            .footer-section-2-social{
                margin-bottom: 20px;
            }
        }
    }
}