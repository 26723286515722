$color-default: #EF9751;
$color-white: #ffffff;
$color-blue: #23265F;
$color-sky: #5DADE2;
$color-sky-pastal: #6186B0;
$color-secondary: #EFEFEF;
$color-gray: #8c8c8c;
$color-success: #3bc13b;
$color-danger: #dc3545;
$color-dark: #000000;

// social
$color-facebook: #3b5998;
$color-Line: #00B900;
$color-youtube: #FF0000;
$color-tiktok: #010101;
$color-twitter: #1DA1F2;
$box-shadows: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
$text-shadows: 0px 3px 12px #00000073;

$max-width: 1228px