.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    // border: 1px solid #5d5d5d;
    width: 250px;
    // height: 100%;
    border-top-right-radius: 10px;
    padding-top: 20px;
    padding-right: 20px;
    z-index: 2;
    overflow: hidden;
    transition: 0.5s all ease;
}

.sidebar .nav .list{
    display: block;
    // text-align: end;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    
    color: #000000;
    transition: 0.5s all ease;
    &:hover{
        background-color: #efefef;
    }
}

.sidebar .nav{
    position: relative;
    top: 100px;
}

.sidebar .nav .list .btn-link{
    .icon{
        margin-right: 10px;
    }
}

.sidebar .nav .list.active{
    color: $color-default;
    background-color: #ef985150;
}

.user-sidebar{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #efefef;
    width: 250px;
    // border-left: 5px solid #ffffff;
    z-index: 1030;
    overflow: hidden;
    transition: 0.5s all ease;
    padding: 20px 0px 0px 0px;

    .logo-img{
        height: 50px;
    }
}

.user-sidebar.hide{
    // width: 0px;
    left: -250px;
}

.user-sidebar.hide~.user-navbar{
    left: 0px;
}

.user-sidebar.hide~.user-main{
    left: 0px;
    width: 100%;
}

.user-sidebar ul{
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    padding-left: 20px;
    padding-top: 10px;
}

.user-sidebar ul a{
    position: relative;
    list-style: none;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    text-decoration: none;
}

.user-sidebar ul a b:nth-child(1){
    position: absolute;
    top: -20px;
    height: 20px;
    width: 100%;
    background: white;
    display: none;
}

.user-sidebar ul a b:nth-child(1)::before{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #efefef;
    border-bottom-right-radius: 20px;
}

.user-sidebar ul a b:nth-child(2){
    position: absolute;
    bottom: -20px;
    height: 20px;
    width: 100%;
    background: white;
    display: none;
}

.user-sidebar ul a b:nth-child(2)::before{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #efefef;
    border-top-right-radius: 20px;
}

.user-sidebar ul a.active b:nth-child(1),
.user-sidebar ul a.active b:nth-child(2)
{
    display: block;
}

.user-sidebar ul a.active {
    display: block;
    background: #ffffff;
}

.user-sidebar ul a .btn-link{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #000000;
    background: transparent;
    border: none;
    cursor: pointer;
}
.user-sidebar ul a.active .btn-link{
    color: #EF9751;
    font-weight: 400;
    background: transparent;
    border: none;
}
.user-sidebar ul a.active .btn-link .icon{
    color: #EF9751;
}
.user-sidebar ul a .btn-link .icon{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 62px;
    text-align: center;
}
.user-sidebar ul a .btn-link .icon .faIcon{
    font-size: 1.2em;
}

.user-sidebar ul a .btn-link .title{
    position: relative;
    display: block;
    padding-left: 10px;
    height: 60px;
    line-height: 60px;
    white-space: normal;
}
