.grid-wrapper{
    display: block;
    .photo-box-1{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: 80vh;

        .photo-item{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
    
    .photo-box-2{
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        width: 100%;
        height: 80vh;

        .photo-row-1{
            width: 100%;
            height: 60%;
    
            .photo-item{
                width: 100%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    
        .photo-row-2{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 40%;
    
            .photo-item{
                width: 50%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }
    
    .photo-box-3{
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        width: 100%;
        height: 80vh;
    
        .photo-row-1{
            width: 100%;
            height: 60%;
    
            .photo-item{
                width: 100%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    
        .photo-row-2{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 40%;
    
            .photo-item{
                width: 50%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }
    .photo-box-4{
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        width: 100%;
        height: 80vh;
    
        .photo-row-1{
            width: 100%;
            height: 60%;
    
            .photo-item{
                width: 100%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    
        .photo-row-2{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 40%;
    
            .photo-item{
                width: 33.3%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }
    
    .photo-box-5{
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 80vh;
    
        .photo-row-1{
            width: 100%;
            height: 60%;
    
            .photo-item{
                width: 100%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    
        .photo-row-2{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 40%;
    
            .photo-item{
                width: 33.3%;
                height: 100%;
                padding: 5px;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    
        .show-more{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 4px;
            right: 5px;
            width: 32.5%;
            height: 38.5%;
            padding: 10px;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            
            .text{
                color: $color-white;
                font-size: x-large;
                font-weight: 500;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .grid-wrapper{
        .photo-box-1{
            height: 40vh;
        }
        .photo-box-2{
            height: 40vh;
        }
        .photo-box-3{
            height: 40vh;
        }
        .photo-box-4{
            height: 40vh;
        }
        .photo-box-5{
            height: 40vh;

            .show-more{
                width: 31%;
                height: 37%;
                .text{
                    color: $color-white;
                    font-size: medium;
                    font-weight: 500;
                }
            }
        }
    }
}
// .grid-wrapper{
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     gap: 10px;

//     .photo-box{
//         .photo-item{
//             width: 100%;
//             border-radius: 10px;
//             object-fit: cover;
//         }
//     }
//     .photo-box:nth-last-child(1){
//         grid-column: 1 / 4;
//         grid-row: 1;
//     }

//     // .photo-box:nth-last-child(2){
//     // }
// }