@media screen and (max-width: 991px) {
    .navbar{
        padding: 20px 20px;
        // position: static !important;

        .container{
            .navbar-collapse{
                display: none;
            }
            .nav-toggle{
                display: block;
            }
            .nav-signup{
                display: block;
                position: absolute;
                right: 18%;
            }
        }
    }

    .nav-open{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: white;
        z-index: 1041;
        overflow: auto;
        padding-top: 20px;
        padding-right: 20px;
        padding-left: 20px;

        .nav-close{
            display: block;
            position: absolute;
            right: 10px;
        }
    }
    .navbar.active{
        // margin-top: 40px;
        position: static;
        .container{
            flex-direction: column-reverse;
            justify-content:unset;
            align-items: unset;
            .nav-toggle{
                display: none;
            }
            .navbar-brand{
                display: none;
            }
            .navbar-collapse{
                display: inline-block;
                position: fixed;
                top: 50px;
                z-index: 1042 !important;
                width: 100%;
                .navbar-nav{
                    flex-direction: column;
                    width: 100%;
                    .nav-item{
                        width: 100%;
                        margin: 10px 5px;
                    }
                }
            }
            
        }
    }

    .sidebar{
        left: -350px;
        transition: 0.5s all ease;
    }

    .sidebar.active{
        left: 0px;
        width: 250px;
        transition: 0.5s all ease;
    }
    .main{
        left: 0;
        background-color: rgb(255, 255, 255);
        padding-left: 0px;
        width: calc(100% - 0px);
        margin-left: auto;
        margin-right: auto;
    }
}