.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.badge:empty {
    display: none;
}
  
.btn .badge {
    position: relative;
    top: -1px;
}

.rounded-pill {
    border-radius: 50rem !important;
  }