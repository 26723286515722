.table{
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    td, th{
        border-top: 1px solid #ddd;
        // border-bottom: 1px solid #ddd;
        padding: 10px;
    }

    td{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.table th{
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: transparent;
    color: $color-default;
}

.table-promotion{
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    td, th{
        padding: 15px;
        &.border-none{
            border: none !important
        }
        
    }

    td{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        border-top: 1px dashed rgb(208, 208, 208);
        min-height: 50px;
        font-weight: 400;
    }

    th{
        top: 0px;
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: transparent;
    
        .header-free{
            background-color: #FFC861;
            height: 80px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .header-premium{
            background-color: #F29E00;
            height: 80px;
            font-size: 25px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        .header-plot{
            background-color: #FFC861;
            height: 80px;
            font-size: 25px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 991px){
    .table{
        table-layout: unset;
    }
    .table-promotion{
        // table-layout:fixed;
        width: 900px;
    }
}