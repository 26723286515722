.errorpage{
    height: 100vh;
    width: 100%;
    background-image: url('../../../img/404\ error.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .btn-container{
        display: flex;
        position: relative;
        width: 100%;
        height: 85%;
        align-items: flex-end;
        justify-content: center;
    }
}

@media screen and (max-width: 991px) {
    .errorpage{
        background-image: url('../../../img/404\ error\ mobile.jpg');
    }
}