*{
    font-size: 16px;
    font-family: 'Prompt', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-weight: 300;
    // scrollbar-color: $color-default $color-default;
    
    ::placeholder{
        color: #8a8ca0c4;
    }
    :-ms-input-placeholder{
        color: #8a8ca0c4;
    }
    ::-webkit-input-placeholder{
        color: #8a8ca0c4;
    }
}

body{
    min-height: 100vh;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    color: black;
}

a{
    text-decoration: none;
}

ul li{
    list-style: none;
}
.ul{
    padding-left: 50px;
    li{
        list-style: disc;
    }
}

.full-w{
    width: 100%;
}
.w-10{
    width: 10%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-40{
    width: 40%;
}
.w-50{
    width: 50%;
}
.main-panel{
    position: relative;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
}

// .container-slider{
//     margin: 0px 10px;

//     .image-gallery-content 
//     .image-gallery-slide 
//     .image-gallery-image{
//         max-height: 70vh !important;
//         border-radius: 10px;
//     }
//     .image-gallery-slide .image-gallery-image{
//         object-fit: cover !important;
//         border-radius: 10px;
//     }
// }

.react-multiple-carousel__arrow--left{
    left: 0px !important;
    
}
.react-multiple-carousel__arrow--right{
    right: 0px !important;
}

.text-center{
    text-align: center !important;
}
.flex-column{
    flex-direction: column;
}

.color-default{
    color: $color-default;
}

.color-darkblue{
    color: $color-blue;
}

.color-sky{
    color: $color-sky;
}

.color-sky-pastal{
    color: $color-sky-pastal
}

.color-gray{
    color: $color-gray
}

.color-black{
    color: black
}

.color-white{
    color: white
}
.color-facebook{
    color: $color-facebook;
}

.color-green{
    color: #54d30b;
}

.color-line{
    color: $color-Line;
}
.color-youtube{
    color: $color-youtube;
}
.color-tiktok{
    color: $color-tiktok;
}
.color-red{
    color: red;
}
.ml-auto{
    margin-left: auto;
}
.mr-auto{
    margin-right: auto;
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-20{
    margin-top: 20px;
}
.mt-30{
    margin-top: 30px;
}
.ml-5{
    margin-left: 5px;
}
.ml-10{
    margin-left: 10px;
}
.ml-20{
    margin-left: 20px;
}
.ml-30{
    margin-left: 30px;
}
.mr-5{
    margin-right: 5px;
}
.mr-10{
    margin-right: 10px;
}
.mr-20{
    margin-right: 20px;
}
.mr-30{
    margin-right: 30px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-30{
    margin-bottom: 30px;
}
.mb-40{
    margin-bottom: 40px;
}
.mb-50{
    margin-bottom: 50px;
}
.pt-10{
    padding-top: 10px;
}
.pt-20{
    padding-top: 20px;
}
.pb-10{
    padding-bottom: 10px;
}
.pb-20{
    padding-bottom: 20px;
}

.flex{
    display: flex;
}

.flex-row{
    flex-direction: row;
}

.align-center{
    align-items: center;
}

.justify-center{
    justify-content: center;
}

.shadow{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.shadow-2{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.shadow-3{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.carousel-container{
    margin-bottom: 40px;
    .carousel-item{
        padding: 10px;
        // border: 1px solid #000;
    }
}

.font-weight-300{
    font-weight: 300 !important;
}
.font-weight-400{
    font-weight: 400;
}
.font-weight-500{
    font-weight: 500;
}
.font-size-xl{
    font-size: x-large;
}
.font-size-sm{
    font-size: 14px;
}

.border{
    border: 0.5px solid rgb(116, 116, 116) !important;
}

.border-red{
    border: 0.5px solid red !important;
}

.border-default{
    border: 0.5px solid $color-default !important;
}

.border-radius-10{
    border-radius: 10px;
}

.bg_default{
    background-color: $color-default;
}
.bg_sky_pastal{
    background-color: $color-sky-pastal;
}

.bg_sky{
    background-color: #5dade23e;
}

.bg_redpink{
    background-color: #F7687E;
}

.bg_warning{
    background-color: #ECB93B;
}

.bg_success{
    background-color: $color-success;
}

.bg_danger{
    background-color: $color-danger;
}

.bg_gray{
    background-color: $color-gray;
}

.bg_lightgray{
    background-color: $color-secondary;
}

.bg_white{
    background-color: #fff !important;
}

.bg-default-dark{
    background-color: #F58601
}

.bg-default-orange{
    background-color: #F29E00
}

.vl {
    border-left: 3px solid $color-gray;
}
.vr {
    border-right: 3px solid $color-gray;
}

.vr1 {
    border-right: 1px solid $color-gray;
}

.main{
    position: relative;
    top: 10px;
    left: 250px;
    width: calc(100% - 250px);
    height: 85vh;
    padding-left: 30px;
    padding-right: 10px;
    padding-top: 20px;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s all ease;
}

.profile_image{
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

input[type=number]::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
}

hr{
    border: 0.5px solid $color-default;
}

hr.color-gray{
    border: 0.5px solid $color-gray;
}

hr.color-sky{
    border: 0.5px solid $color-sky;
}

/* width */
#style-1::-webkit-scrollbar {
    width: 8px;
}
  
/* Track */
#style-1::-webkit-scrollbar-track {
    background-color: rgb(255, 255, 255);
    // border-radius: 10px;
}

/* Handle */
#style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #F90;	
	background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent)
}

#style-2::-webkit-scrollbar {
    width: 8px !important;
}

#style-2::-webkit-scrollbar-track{
    background-color: #ffffff;
}

#style-2::-webkit-scrollbar-thumb{
    background-color: var(--color-custom);
    background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent);
    border-radius: 10px;
}

/* Handle on hover */
// body::-webkit-scrollbar-thumb:hover {
//     background-color: #a16331; 
// }

.pswp img {
    max-width: none;
    object-fit: contain;
}

.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}
  
.reveal.active{
    transform: translateY(0);
    opacity: 1;
}

.user-main{
    position: relative;
    height: 100%;
    width: calc(100% - 250px);
    left: 250px;
    top: 80px;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    transition: all 0.5s ease;
    background-color: #ffffff;
}

.line-chat{
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* width: 100px; */
    height: 50px;
    cursor: pointer;
    z-index: 1100;
}
.text-telling{
    display: block;
    position: relative;
    height: fit-content; 
    padding: 5px; 
    border-radius: 5px;
    background-color: white;
    color: black;
}
.text-telling.block{
    display: none;
}