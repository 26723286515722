h1,h2,h3,h4,h5,h6{
    font-weight: 400;
}
h1, .h1 {
    font-size: 3.5em;
    line-height: 1.15;
    margin-bottom: 15px * 2;
}
h1.tltd-header {
    font-size: 2em;
    line-height: 1.15;
    margin-bottom: 15px * 2;
}
h2, .h2{
    font-size: 2.5em;
    margin-bottom: 15px * 2;
}
h3, .h3{
    font-size: 2em;
    margin-bottom: 15px * 2;
    line-height: 1.4em;
}
h4, .h4{
    font-size: 1.714em;
    line-height: 1.45em;
    margin-bottom: 15px;

    & + .category,
    &.title + .category{
        margin-top: -10px;
    }
}
h5, .h5 {
    font-size: 1.57em;
    line-height: 1.4em;
}
h6, .h6{
    font-size: 1em;
    font-weight: 700;
}
label{
    font-size: 16px;
    font-weight: 300;
}

small, .small{
    font-size: small;
    font-weight: 200;
}
.font-weight-500{
    font-weight: 500;
}

strong, .strong{
    font-weight: bold;
}

b, .b{
    font-weight: bold !important;
}