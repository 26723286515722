.fixed-plugin{
    position: fixed;
    right: 0;
    width: 64px;
    background: rgba(0,0,0,.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
    top: 120px;

    li > a,
    .badge{
        transition: all .34s;
        -webkit-transition: all .34s;
        -moz-transition: all .34s;
    }

    .fa-cog{
        color: #FFFFFF;
        padding: 10px;
        border-radius: 0 0 6px 6px;
        width: auto;
    }

    .dropdown .dropdown-menu{
        right: 80px;
        left: auto !important;
        top: 0px !important;
        width: 290px;
        border-radius: 0.1875rem;
        padding: 0 10px;
    }

    .dropdown .dropdown-menu .now-ui-icons{
      top: 5px;
    }

    .dropdown-menu:after,
    .dropdown-menu:before{
        right: 10px;
        margin-left: auto;
        left: auto;
    }

    .fa-circle-thin{
        color: #FFFFFF;
    }

    .active .fa-circle-thin{
        color: #00bbff;
    }

    .dropdown-menu > .active > a,
    .dropdown-menu > .active > a:hover,
    .dropdown-menu > .active > a:focus{
        color: #777777;
        text-align: center;
    }

    li.adjustments-line,
    li.header-title,
    li.button-container{
        // width: 100%;
        height: 50px;
        // min-height: inherit;
    }

    .dropdown{
        .dropdown-menu{
             -webkit-transform: translateY(-15%);
             -moz-transform: translateY(-15%);
             -o-transform: translateY(-15%);
             -ms-transform: translateY(-15%);
             transform: translateY(-15%);
             top: 27px;
             opacity: 0;

             transform-origin: 0 0;

             &:before{
                 border-bottom: .4em solid rgba(0, 0, 0, 0);
                 border-left: .4em solid rgba(0,0,0,0.2);
                 border-top: .4em solid rgba(0,0,0,0);
                 right: -16px;
                 top: 46px;
             }

             &:after{
                 border-bottom: .4em solid rgba(0, 0, 0, 0);
                 border-left: .4em solid #FFFFFF;
                 border-top: .4em solid rgba(0,0,0,0);
                 right: -16px;
             }

             &:before,
             &:after{
                 content: "";
                 display: inline-block;
                 position: absolute;
                 top: 74px;
                 width: 16px;
                 transform: translateY(-50%);
                 -webkit-transform: translateY(-50%);
                 -moz-transform: translateY(-50%);
             }
        }

        &.show .dropdown-menu{
             opacity: 1;

             -webkit-transform: translateY(-13%);
             -moz-transform: translateY(-13%);
             -o-transform: translateY(-13%);
             -ms-transform: translateY(-13%);
             transform: translateY(-13%);

             transform-origin: 0 0;
        }
    }

    .bootstrap-switch{
        margin:0;
    }
}
