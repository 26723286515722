.btn{
    cursor: pointer;
    border: none;
    &.disabled{
        background-color: #d1d1d1;
        color: $color-gray;

        &:hover{
            transform: none;
            box-shadow: none;
        }
    }
}

.btn-default{
    padding: 10px 20px;
    background-color: $color-default;
    color: $color-white;
    border-radius: 10px;
    text-align: center;
}
.btn-default-sm{
    padding: 5px 20px;
    background-color: $color-default;
    color: $color-white;
    border-radius: 5px;
    text-align: center;

    &.disabled{
        color: white;
        background-color: $color-gray;
    }
}

.btn-danger{
    padding: 10px 20px;
    background-color: $color-danger;
    color: $color-white;
    border-radius: 10px;
    text-align: center;
}

.btn-sky{
    padding: 10px 20px;
    background-color: $color-sky;
    color: $color-white;
    border-radius: 10px;
    text-align: center;
}

.btn-circle-md{
    width: 100%;
    padding: 10px;
    background-color: $color-default;
    color: $color-white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-round-sky-sm{
    width: 38px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: $color-sky;
    color: $color-white;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-round-danger-sm{
    width: 38px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: $color-danger;
    color: $color-white;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-round-default-sm{
    width: 38px;
    height: 38px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: $color-default;
    color: $color-white;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-skypastal{
    padding: 10px;
    background-color: $color-sky-pastal;
    color: $color-white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-white{
    padding: 10px 20px;
    background-color: $color-white;
    color: $color-default;
    border-radius: 10px;
    text-align: center;
    box-shadow: $box-shadows;
}

.btn-blue{
    padding: 5px 20px;
    background-color: $color-white;
    color: $color-sky-pastal;
    border-radius: 10px;
    text-align: center;
    border: 2px solid $color-sky-pastal;
}

.btn-success{
    padding: 5px 20px;
    background-color: $color-success;
    color: white;
    border-radius: 10px;
    text-align: center;
    // border: 2px solid $color-sky-pastal;
    
}

.animated{
    transition: 0.5s all ease;
    &:hover{
        transform: translateY(-5px);
        box-shadow: $box-shadows;
    }
}

.btn-default-outline{
    padding: 8px 20px;
    background-color: $color-white;
    color: $color-default;
    border-radius: 10px;
    text-align: center;
    border: 2px solid $color-default;

    &.disabled{
        border: 2px solid $color-gray;
    }
}

.btn-gray-outline{
    padding: 8px 20px;
    background-color: lightgray;
    color: $color-white;
    border-radius: 10px;
    text-align: center;
    border: 2px solid $color-gray;
}

.btn-transparent{
    background-color: transparent;
    color: $color-default;
}

.btn-transparent-sm{
    padding: 5px 20px;
    background-color: transparent;
    color: $color-default;
    text-align: center;
}

.btn-gray{
    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid $color-gray;
    background-color: $color-white;
}

.btn-application-download{
    display:flex;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 5px 10px;
    color: gray;

    &:hover{
        color: $color-default;
    }
}

.btn-selected{
    background-color: $color-default;
    color: $color-white;
    border-radius: 10px;
    text-align: center;
    transition: 0.5s all ease;
    border: 2px solid $color-white;
}

.btn-sm{
    padding: 3px 15px !important;
    font-weight: 500;
    border-radius: 5px;
}

.btn-white-transparent{
    padding: 5px 20px;
    border-radius: 5px;
    background-color: $color-white;
}

.btn-next{
    padding: 5px 20px;
    margin: 5px;
    background-color: $color-white;
    border: 2px solid $color-default;
    color: $color-default;
    border-radius: 5px;

    &.disabled{
        border: 2px solid $color-gray;
    }
}

.btn-package{
    text-align: left;
    padding: 20px 10px;
    width: 100%;
    border-radius: 10px;
    background-color: $color-white;
    border: 3px solid lightgray;
    margin-bottom: 20px;
    
    &:hover{
        border: 3px solid $color-success;
    }

    &.active{
        text-align: left;
        padding: 20px 10px;
        width: 100%;
        border-radius: 10px;
        background-color: #eafee500;
        border: 3px solid $color-success;
        margin-bottom: 20px;
        .icon-check{
            color: $color-success;
        }
    }
}

.btn-facebook{
    padding: 10px 20px;
    background-color: $color-facebook;
    color: $color-white;
    border-radius: 10px;
    text-align: center;
}

.btn-line{
    padding: 10px 20px;
    background-color: $color-Line;
    color: $color-white;
    border-radius: 10px;
    text-align: center;
}

.btn-google{
    display: block;
    position: relative;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border-radius: 10px;
    text-align: center;

    .loading{
        display: block;
        align-items: center;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
    }
}

.btn-share-facebook{
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background-color: $color-facebook !important;
    &:hover{
        background-color: #546a9a !important;
    }
}

.btn-share-line{
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background-color: $color-Line !important;

    &:hover{
        background-color: #53bb53 !important;
    }
}

.btn-share-twitter{
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background-color: $color-twitter !important;
    &:hover{
        background-color: #66bdf4 !important;
    }
}

.btn-share-copylink{
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background-color: $color-secondary !important;
    &:hover{
        background-color: #d1d1d1 !important;
    }
}

.btn-report{
    padding: 5px 20px;
    background-color: $color-secondary;
    color: gray;
    border-radius: 5px;
    text-align: center;
    transition: 0.5s all ease;
    border: 2px solid gray;
    transition: 0.2s all ease-in-out;
    
    &.active{
        background-color: $color-default;
        border: 2px solid $color-default;
        color: white;
    }
}

.btn-favorite{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background-color: rgba(0,0,0,0.5);
    
    &:hover{
        background-color: rgba(0,0,0,0.8);
    }
}

.btn-share-estate{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background-color: rgba(0,0,0,0.5);
    
    &:hover{
        background-color: rgba(0,0,0,0.8);
    }
}

.btn-share{
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background-color: rgba(0,0,0,0.5);

    &:hover{
        background-color: rgba(0,0,0,0.8);
    }
}

.btn-fly{
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    border: 2px solid white;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    background-color: rgb(46, 134, 193);
    transition: 0.3s all ease;
    &:hover{
        background-color: rgba(46, 134, 193,0.8);
        transform: translateY(-5px);
    }
}

.btn-secondary{
    padding: 10px 20px;
    background-color: $color-gray;
    color: $color-white;
    // border-radius: 10px;
    text-align: center;
}

.image-gallery-icon.image-gallery-left-nav:hover{
    color: #EF9751;
}
.image-gallery-icon.image-gallery-right-nav:hover{
    color: #EF9751;
}