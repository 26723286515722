.card{
    position: relative;
    display: block;
    width: 100%;
    border-radius: 10px;
    box-shadow: $box-shadows;
    background-color: white;

    .card-img{
        object-fit: cover;
        width: 100%;
        height: 200px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .card-video{
        width: 100%;
        height: 200px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        & iframe{
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    .card-body{
        padding: 10px 10px;
        // padding-bottom: 10px;
        word-wrap: break-word;
        word-break: break-all;
        .body-1{
            margin-bottom: 20px;
            .card-title{
                font-size: 18px;
                font-weight: 500;
            }
            .card-text{
                font-size: 16px;
            }
        }
        .body-2{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .card-title{
            font-size: 18px;
            font-weight: 500;
        }
        .card-text{
            font-size: 16px;
        }
    }

    .card-footer.flex-center{
        display: flex;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .card-footer.flex-between{
        display: flex;
        justify-content:space-between;
        padding-top: 10px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}